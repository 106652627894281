// extracted by mini-css-extract-plugin
export var additionalPadding = "ProductReservation__additionalPadding__fjPMX";
export var buttonContainer = "ProductReservation__buttonContainer__cSgvJ";
export var column = "ProductReservation__column__GkuYM";
export var confirmationNumberLabel = "ProductReservation__confirmationNumberLabel__h8ck8";
export var error = "ProductReservation__error__nJmcv";
export var field = "ProductReservation__field__rRKgt";
export var field2MdLg = "ProductReservation__field2_md_lg__jnT6V";
export var fieldGroup1MdLg = "ProductReservation__fieldGroup1_md_lg__r1Y6Q";
export var fieldGroup2AMdLg = "ProductReservation__fieldGroup2a_md_lg__RICTN";
export var fieldGroup2BMdLg = "ProductReservation__fieldGroup2b_md_lg__jv8x1";
export var fieldGroupMdLg = "ProductReservation__fieldGroup_md_lg__xDvqK";
export var fistCheckbox = "ProductReservation__fistCheckbox__lz_co";
export var formContainer = "ProductReservation__formContainer__s3Wl1";
export var formSubtitle = "ProductReservation__formSubtitle__ibn3x";
export var formTitle = "ProductReservation__formTitle__NRJfv";
export var layout = "ProductReservation__layout__vq5iX";
export var nextStepsList = "ProductReservation__nextStepsList__crd70";
export var productDetails = "ProductReservation__productDetails__uLcC_";
export var productDetailsComplete = "ProductReservation__productDetailsComplete__lj24Z";
export var row = "ProductReservation__row__mbG6q";